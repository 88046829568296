import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
// import { BLOCKS, MARKS } from "@contentful/rich-text-types"
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LogoSlider from '../components/slider/slider';

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

import homeBgImage from '../images/pages/home/banner.jpg';
import hensImage from '../images/pages/home/hens.jpg';
import stagImage from '../images/pages/home/stag.jpg';
import storyBgImage from '../images/pages/home/story-bg.jpg';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '../styles/styles.scss';

// const Bold = ({ children }) => <span className="bold">{children}</span>
// const Text = ({ children }) => <p className="align-center">{children}</p>

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
//   },
//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
//   },
// }

const HomePage = ({ data }) => {
  const { metaTitle } = data.contentfulHomePage;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero" style={{backgroundImage:`url(${homeBgImage})`}}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="hero-text-container">
                  <h1>We’re proud to be the most caring creators of brilliant group experiences</h1>
                  <h4>We specialise in pre-nuptial celebrations and luxury getaways.</h4>
                </div>
              </Col>
            </Row>
          </Container>
          <Link className="main-button" to="/our-brands" value="See our brands">See our brands</Link>
        </div>
        <div className="our-brands">
          <Container>
            <h2>Our brands</h2>
          <Row className="row-eq-height">
            <Col md={6}>
              <img alt="Hen Heaven" src={hensImage} />
              <h3>Hen Heaven</h3>
              <p>Hen Heaven is one of the UK’s top hen weekend providers, with over 15 years of experience.</p>
            </Col>
            <Col md={6}>
              <img alt="Stag Company" src={stagImage} />
              <h3>The Stag Company</h3>
              <p>The UK’s favourite stag do organisers, The Stag Company has been helping bewildered best men since 2002.</p>
            </Col>
          </Row>
          </Container>
        </div>
        <div className="our-story-section" style={{backgroundImage:`url(${storyBgImage})`}}>
          <Container>
            <Row>
              <Col md={12}>
                <h2>Our Story</h2>
                <h4>Established in 2002, The Eventa Group operates several successful brands within the travel and events sector and is the brainchild of ex-accountant Rob Hill.</h4>
                <p>The Eventa Group operates several successful brands within the travel and events sector and is the brainchild of ex-accountant Rob Hill. At the age of 25, Rob bought out his previous employer and replaced him as CEO of Hen Heaven, organising group travel experiences for brides-to-be and their friends and family, across the UK and Europe. During this time, Rob also created The Stag Company, to mirror the concept for best men who were struggling with the duty and honour of organising an epic weekend for their nearest and dearest.</p>
              {/* {documentToReactComponents(ourStoryText.json, options)} */}
              <Link className="main-button" to={'/about-us'} value="Learn more">Learn More</Link>
              </Col>
            </Row>
          </Container>
        </div>
        <LogoSlider />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    contentfulHomePage {
      metaTitle
      pageTitle
      slug
      heroText {
        json
      }
      updatedAt
      brandsLeftTitle
      brandsRightTitle
      brandsLeftParagraph
      brandsRightParagraph
      ourStoryText {
        json
      }
    }
  }
`;