import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import abtaLogo from '../../images/abta.jpg';
import cipdLogo from '../../images/cipd.jpg';
import fastrackLogo from '../../images/fasttrack.jpg';
import healthLogo from '../../images/health-assured.jpg';
import mhfaLogo from '../../images/mhfa.jpg';
import sbaLogo from '../../images/sba.jpg';
import vistageLogo from '../../images/vistage.jpg';
import younggunsLogo from '../../images/youngguns.jpg';

import { Container } from 'react-bootstrap';

const LogoSlider = () => {

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  }

  const images = [ abtaLogo, cipdLogo, fastrackLogo, healthLogo, mhfaLogo, sbaLogo, vistageLogo, younggunsLogo ];

  const renderImages = images.map(function(image, index ) {
    return(
      <div key={index} className="img-outer">
        <img src={image} alt="logo" />
      </div>
    );
  })

  return (
    <div className="slider-outer">
      <Container>
        <Slider {...settings}>
          {renderImages}
        </Slider>
      </Container>
    </div>
  );
}

export default LogoSlider; 
